import entity_smt from "./production/entity_smt";
import entity_smt_investor from "./production/entity_smt_investor";
import entity_smt_reports from "./production/entity_smt_reports";
import entity_smt_terms from "./production/entity_smt_terms";
import entity_smt_compliance_form from "./production/entity_smt_compliance_form";
import entity_thefitfoodietravels from "./production/entity_thefitfoodietravels";
import entity_5BCP3VNH from "./production/entity_5BCP3VNH";
import entity_0RY2AJUU from "./production/entity_0RY2AJUU";
import entity_YXZ11MSY from "./production/entity_YXZ11MSY";
import entity_pavyhsio from "./production/entity_pavyhsio";
import entity_aaysrqb3 from "./production/entity_aaysrqb3";
import entity_e8bqlyoq from "./production/entity_e8bqlyoq";
import entity_BL0GEOJK from "./production/entity_BL0GEOJK";
import entity_0eub3hsk from "./production/entity_0eub3hsk";
import entity_pt4dar5o from "./production/entity_pt4dar5o";
import entity_0vhyw7zh from "./production/entity_0vhyw7zh";
import entity_nxpkazaw from "./production/entity_nxpkazaw";
import entity_supercheckout from "./production/entity_supercheckout";
import entity_kalakritikarishma from "./production/entity_kalakritikarishma";
import entity_bunchoftraders from "./production/entity_bunchoftraders";
import entity_5tnsxd1x from "./production/entity_5tnsxd1x";
import entity_T3PV769W from "./production/entity_T3PV769W";
import entity_pd1bw5z6 from "./production/entity_pd1bw5z6";
import entity_thefitfoodietravels_meeting from "./production/entity_thefitfoodietravels_meeting";
import entity_baazigarcricketexpert from "./production/entity_baazigarcricketexpert";
import entity_47ixzvf8 from "./production/entity_47ixzvf8";


const configsProductionByUrl = {
  "/channels/5bcp3vnh": {
    type: "channel",
    config: entity_5BCP3VNH,
  },
  "/channels/yxz11msy": {
    type: "channel",
    config: entity_YXZ11MSY,
  },
  "/channels/0ry2ajuu": {
    type: "channel",
    config: entity_0RY2AJUU,
  },
  "/smt": {
    type: "profile",
    config: entity_smt,
  },
  "/smt/investor-charter": {
    type: "profile",
    config: entity_smt_investor,
  },
  "/smt/terms": {
    type: "profile",
    config: entity_smt_terms,
  },
  "/smt/sebi-compliance-form": {
    type: "profile",
    config: entity_smt_compliance_form,
  },
  "/channels/pavyhsio": {
    type: "channel",
    config: entity_pavyhsio,
  },
  "/channels/aaysrqb3": {
    type: "channel",
    config: entity_aaysrqb3,
  },
  "/channels/bl0geojk": {
    type: "channel",
    config: entity_BL0GEOJK,
  },
  "/channels/0eub3hsk":{
    type: "channel",
    config: entity_0eub3hsk,
  },
  "/channels/e8bqlyoq": { // Note: didn't make anything for it in staging.configs.js as this is similar to
    // entity_aaysrqb3 and entity_pavyhsio
    type: "channel",
    config: entity_e8bqlyoq,
  },
  "/channels/x4umzncz": { // Note: testing page
    type: "channel",
    config: entity_pavyhsio,
  },
  "/channels/e2rx9n07": { // Note: testing page
    type: "channel",
    config: entity_aaysrqb3,
  },
  "/thefitfoodietravels": {
    type: "profile",
    config: entity_thefitfoodietravels,
  },
  "/channels/cwm2obye": {
    type: "channel",
    config: entity_supercheckout,
  },
  "/channels/wdk02wjv": {
    type: "channel",
    config: entity_supercheckout,
  },
  "/channels/to6j0p3r": {
    type: "channel",
    config: entity_supercheckout,
  },
  "/channels/pt4dar5o": {
    type: "channel",
    config: entity_pt4dar5o,
  },
  "/channels/0vhyw7zh": {
    type: "channel",
    config: entity_0vhyw7zh,
  },
  "/channels/nxpkazaw": {
    type: "channel",
    config: entity_nxpkazaw,
  },
  "/kalakriti_karishma": {
    type: "profile",
    config: entity_kalakritikarishma,
  },
  "/bunchoftraders": {
    type: "profile",
    config: entity_bunchoftraders,
  },
  "/channels/5tnsxd1x": {
    type: "channel",
    config: entity_5tnsxd1x
  },
  "/premium-content/t3pv769w": {
    type: "premium-content",
    config: entity_T3PV769W
  },
  "/courses/pd1bw5z6": {
    type: "course",
    config: entity_pd1bw5z6
  },
  "/book-slots/99e73q23": {
    type: "one_to_one_meeting",
    config: entity_thefitfoodietravels_meeting,
  },
  "/book-slots/vw1jon8m": {
    type: "one_to_one_meeting",
    config: entity_thefitfoodietravels_meeting,
  },
  "/courses/byg8lb0s" : {
    type: "course",
    config: entity_baazigarcricketexpert,
  },
  "/channels/47ixzvf8" : {
    type: "channel",
    config: entity_47ixzvf8,
  }
};

export default configsProductionByUrl;
