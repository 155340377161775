import {QOOHOO_WEBSITE_DOMAIN} from "@qoohoo/common/constants/env";

const entity_5BCP3VNH = {
  seo: {
    title: 'Learn and Earn the right way - A course on Stock Market',
    description: `A course specifically designed for anyone who wants to learn simple and effective ways to earn from Stock Market using Technical Analysis. This Program typically helps in generating SHORT TERM TO LONG TERM INVESTING ideas with the aim to maximize profit with minimum risk.`,
    image: `${QOOHOO_WEBSITE_DOMAIN}/assets/templates/template1/feature-img.png`,
  },
  template: 'template1',
  sections: [
    {
      type: 'HERO',
      data: {
        title: 'Learn and Earn the right way - A course on Stock Market',
        description:
          `<p>
            A course specifically designed for anyone who wants to learn simple and effective ways to earn from Stock Market using Technical Analysis.
            This Program typically helps in generating <b>SHORT TERM TO LONG TERM INVESTING</b> ideas with the aim to maximize profit with minimum risk.
            <br />
            <br />
            <span class="font-semibold">
              Learn -
              <ul>
                <li>• Banknifty Future and Options</li>
                <li>• Stocks Trades</li>
                <li>• Commodities and Currency</li>
              </ul>
            </span>
          </p>`
        ,
        paymentButtonLabel: 'Enroll Now',
        creatorInfo: {
          name: 'Sarang Yadav',
          bio: '5+ Year Experience | 500+ Students Trained | 100% return Yearly | NISM certified'
        },
        featureImgUrls: ['/assets/templates/template1/feature-img.png', '/assets/templates/template1/feature-img-2.png'],
        features: [
          {
            icon: '/assets/templates/template1/icon-calendar.svg',
            label: 'Start from 1st Dec',
          },
          {
            icon: '/assets/templates/template1/icon-ticket.svg',
            label: 'Learn from LIVE classes'
          },
          {
            icon: '/assets/templates/template1/icon-video.svg',
            label: 'Get class recording'
          }
        ]
      }
    },
    {
      type: 'HIGHLIGHTS',
      data: {
        title: 'Course key highlights',
        items: [
          'Learn from very Basics to Advance concepts',
          'One-to-One Guidance about your mistakes and work',
          '1 Month Live Trading and Hand holding in Live Market',
          'Learn Commodities and earn in Evening too if you are working class',
          'Learn how to grow and make consistent income'
        ],
      }
    },
    {
      type: 'FEATURES',
      data: {
        title: 'What will you learn?',
        items: [
          'Basic introduction to stock market',
          'Market terminology',
          'Technical analysis and fundamental analysis',
          'Most useful candlesticks and their uses',
          'Trendlines, support and resistance',
          'Market psychology',
          'Trading secrets and rules',
          'Trading mistakes',
          'What are strategies?',
          'How to build a strategy',
          'Best strategies and indicators',
          'Swing trading',
          'Risk & portfolio management',
          'Generate monthly income from stock market'
        ],
        paymentButtonLabel: 'Enrol Now'
      }
    },
    {
      type: 'SCHEDULE',
      data: {
        title: 'Class schedule',
        items: [
          {
            icon: '/assets/templates/template1/icon-calendar.svg',
            description: 'Monday to Friday - Live Trades from 9:15am to 10:00pm'
          },
          {
            icon: '/assets/templates/template1/icon-calendar.svg',
            description: 'Saturday and Sunday - Live Classes from 11:00am to 1:30pm'
          },
          {
            icon: '/assets/templates/template1/icon-video.svg',
            description: 'Recording of the classes will be given'
          },
        ],
      }
    },

    {
      type: 'CUSTOMER_REVIEWS',
      data: {
        title: 'Here\'s what our learners say',
        images: [
          '/assets/templates/template1/review1.png',
          '/assets/templates/template1/review2.png',
          '/assets/templates/template1/review3.png',
          '/assets/templates/template1/review4.png',
          '/assets/templates/template1/review5.png'
        ]
      }
    },
    {
      type: 'CREATOR_INTRO',
      data: {
        title: 'Meet your Creator',
        name: 'Sarang Yadav',
        photo_url: '/assets/templates/template1/creator-img.png',
        description: `
  An Entrepreneur running two successful business. She started her journey in Stock Market back in 2017 when while doing research for a client of hers she got to know about Markets. The quotes "She didn't choose Markets, markets chose her" applies to her journey very well. Starting as a newbie and not knowing anything about Markets to being a very successful trader she had her ups and downs. Learned from many, even got wrong knowledge or rather cheated from many. But, it never went in vain as she decided to help other retailors from making same mistakes as she did or at least cut short their journey by a lot. Hope on the Train called Sarang Tradiology where not just Trade by the emotions and psychology are understood. Where we don't change the markets, but try to change ourselves according to the Market. As the saying goes: "Markets are supreme."
  `
      }
    },
    {
      type: 'FAQ',
      data: [
        {
          q: "How will the course work?",
          a: "You will be added to a Telegram group where you will get link to join live classes and will get Live market calls too.",
        },
        {
          q: "Will I get the Class Recording?",
          a: "Yes. You will get a Class recording and you can watch the same at any time of your convenience.",
        },
        {
          q: "How much support will I receive during the training?",
          a: "As much as you need, we have never left out any doubt unresolved. We are proud and known for our mentor's unstoppable desire to help other traders & investors.",
        },
        {
          q: "Is this course also for beginners or only experienced?",
          a: "Both! We explain all the concepts from very basic to advance levels, all you need is a desire to learn. Rest we will provide you with everything you need to succeed in Stock Market.\n"
        },
        {
          q: "What makes your course different from others?",
          a: "Our mentor's dedication towards students and a personalized learning experience, from a successful mentor having experience 5 years."
        }

      ]
    },
  ]
};

export default entity_5BCP3VNH;