const entity_aaysrqb3 = {
  login_settings: {
    isWhatsappOptInChecked: true,
    heading: "Enter your WhatsApp number",
    user_form: {
      email: true
    }
  },
  thankyou_modal_settings: {
    heading: "Message from creator",
    message: "Thank you for joining my community. I will add you to the WhatsApp community.",
    has_join_btn: false
  },
  removeSupportChatButton: true,
  theme: {
    is_telegram_white_theme: true,
    is_whatsapp_white_theme: false,
  },
};

export default entity_aaysrqb3;