const entity_T3PV769W = {
    settings: {
      checkout: {
        pre_payment_form: {
          order: ["email"],
        }
      }
    },
  };
  
  export default entity_T3PV769W;