const entity_47ixzvf8 = {
  settings: {
    checkout: {
      pre_payment_form: {
        order: ["email"],
      }
    },
  }
};

export default entity_47ixzvf8;
