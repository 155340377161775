import { qoohooWebsiteDomain } from "constants/domain";
import entity_smt_footer from "./footers/entity_smt_footer";

const INTEGRATION_OPEN_DELAY = 3000;

const entity_smt = {
  seo: {
    title: "SMT™ • Learn trade from SEBI registered investors only",
    description: `SMT™ Academy is technical analysis firm created to provide unbiased stock/share market technical trading tips or trading analysis with the aim of protecting the innocent traders who invest their hard earned money in the rich man’s game, i.e. stock market, without any basic knowledge and not knowing the nuances of stock market game, especially in the area of option trading.`,
    image: "https://qoohoo.in/assets/templates/entity_smt/og-img.png",
  },
  template: "template3",
  navLinks: [
    {
      label: "Blog",
      url: "https://smtstockmarkettoday.medium.com"
    },
    {
      label: "Investor Charter",
      url: "/investor-charter",
    },
    {
      label: "Telegram Channels",
      url: "#telegram-channels",
      target: ""
    },
  ],
  footer: entity_smt_footer,
  meta: [{
    name:'facebook-domain-verification',
    content: "kdoslii9n7vqvpzx8vzsxl1sxy9t53"
  }],
  settings: {
    customDomain: "https://smtstockmarkettoday.com",
    support_link: {
      label: "Join us",
      type: "telegram",
      link: "https://telegram.me/SMTStockMarketToday",
      track: {
        twitter_pixel: {
          property: 'event',
          value: "tw-o90ss-odte4",
        },
      }
    },
    integrations: {
      fb_pixel: {
        FB_PIXEL_IDS: ["1315609519279227"],
      },
      twitter_pixel: {
        TWITTER_PIXEL_ID: "o90ss",
      },
      google_analytics: {
        GA_TRACKING_ID: "G-QY7SGX4LD4",
      },
      google_tag_manager: {
        GTM_ACCOUNT_ID: "GTM-MT4K2QZ",
      },
      integration_kit: {
        data: {
          format: "popup",
          type: "free_signup",
          heading_text: "Join a thriving community of successful traders",
          description_text: "We are a SEBI-registered trading academy with more than 115k trusted members on telegram.",
          cta_button: {
            label: "Join for FREE trial",
            link: "https://telegram.me/SMTStockMarketToday"
          },
          display_image: `${qoohooWebsiteDomain}/assets/templates/entity_smt/popup-image.png`,
        },
        showOptions: {
          on_scroll: true,
          with_delay: INTEGRATION_OPEN_DELAY,
        }
      }
    },
  },
  sections: [
    {
      type: "HERO",
      data: {
        title: `<p>Get Equity & Derivatives Research Recommendation From A <font color=#FFC831>SEBI Registered RA</font></p>`,
        bg_image: "/assets/templates/entity_smt/hero-bg.jpeg",
        description: `<p>
              Highly accurate calls!
            </p>`,
        button: {
          label: "Join for FREE trial",
          link: "https://telegram.me/SMTStockMarketToday",
          track: {
            twitter_pixel: {
              property: 'event',
              value: "tw-o90ss-odte4",
            },
          }
        },
        arrowIcon: "/assets/templates/entity_smt/arrow.svg",
        creatorInfo: {
          name: "SMT™",
          bio: "",
        },
        features: [
          {
            icon: "/assets/social-icons/yt-coloured.svg",
            label: `<p>Trusted by <b>80k+</b> Subscribers</p>`,
          },
          {
            icon: "/assets/templates/entity_smt/telegram.svg",
            label: `<p><b>115k+</b> Telegram subscribers</p>`,
          },
          {
            icon: "/assets/templates/entity_smt/award.svg",
            label: `<p><b>SEBI</b> registered</p>`,
          },
          {
            icon: "/assets/templates/entity_smt/check-circle.svg",
            label: `<p>Highly Accurate Trades</p>`,
          },
        ],
      },
    },
    {
      type: "ABOUT",
      data: {
        subtitle: "WELCOME TO",
        title: "STOCK MARKET TODAY",
        description: `<p>
        STOCK MARKET TODAY is a <b>SEBI-registered</b> technical analysis firm created to provide unbiased stock/share market technical trading tips or trading analysis with the aim of protecting the innocent traders who invest their hard earned money in the rich man’s game, i.e. stock market, without any basic knowledge and not knowing the nuances of stock market game, especially in the area of option trading. STOCK MARKET TODAY is headed by a professionally qualified and well knowledgeable independent Research Analyst in the share/stock market with 15 years investment experience and more dedicated with full time devotion in the field of technical research in the stock market since 2005.
        </p>`,
        items: [
          {
            icon: "/assets/templates/entity_smt/bnc.png",
            label: "Biz News Connect",
            link: "https://biznewsconnect.com/markets/article-the-rrr-of-markets-risk-research-responsibility",
          },
          {
            icon: "/assets/templates/entity_smt/livemint.png",
            label: "Live Mint",
            link: "https://www.livemint.com/money/personal-finance/top-long-term-investments-that-can-generate-regular-monthly-income-11681574016873.html",
          },
          {
            icon: "/assets/templates/entity_smt/timesofindia.png",
            label: "thetimeofindia",
            link: "https://timesofindia.indiatimes.com/blogs/voices/guide-to-making-smart-choices-in-derivatives-by-market-analysis/",
          },
          {
            icon: "/assets/templates/entity_smt/financialexpress.png",
            label: "Financial Express",
            link: "https://www.financialexpress.com/market/share-market-outlook-today-nifty-bank-nifty-may-remain-volatile-on-fo-expiry-check-support-resistance-4/3073548/",
          },
          {
            icon: "/assets/templates/entity_smt/businesstoday.png",
            label: "Business Today",
            link: "https://www.businesstoday.in/markets/company-stock/story/manappuram-finance-shares-rebound-5-today-in-fag-end-trade-heres-why-380046-2023-05-04",
          },
        ],
      },
    },
    {
      type: "FEATURES_BAR",
      data: {
        items: [
          "SEBI REGISTERED",
          "TECHNICAL ANALYSIS",
          "LIVE TRADING",
          "BANK NIFTY CALLS",
        ],
      },
    },
    {
      type: "CHANNELS",
      id: "telegram-channels",
      data: {
        title: "Learn from our Telegram channels",
        title_logo: "/assets/templates/entity_smt/telegram.svg",
        description: `
        <p>More than 5000 traders have achieved success with us and recovered all their losses 📈</p>`,
        joinButtonLabel: "Join Now",
      },
    },
    {
      type: "FEATURES",
      data: {
        title: "Why choose us?",
        items: [
          {
            icon: "/assets/templates/entity_smt/features/1.png",
            label: "Free Live Trading With You",
          },
          {
            icon: "/assets/templates/entity_smt/features/2.png",
            label: "Dedicated team",
          },
          {
            icon: "/assets/templates/entity_smt/features/3.png",
            label: "Option Trade Position Share",
          },
          {
            icon: "/assets/templates/entity_smt/features/4.png",
            label: "Bank Nifty Calls With Big Target Like (100-150 Point)",
          },
        ],
      },
    },
    {
      type: "CUSTOMER_REVIEWS",
      data: {
        title: "Our customers love us ❤️",
        reviews: [
          {
            link: "/assets/templates/entity_smt/reviews/1.jpeg",
            type: "image",
          },
          {
            link: "https://qoohoo-public.s3.ap-south-1.amazonaws.com/smt/reviews/videos/1.m3u8",
            type: "video",
          },
          {
            link: "/assets/templates/entity_smt/reviews/2.jpeg",
            type: "image",
          },
          {
            link: "https://qoohoo-public.s3.ap-south-1.amazonaws.com/smt/reviews/videos/2.m3u8",
            type: "video",
          },
          {
            link: "/assets/templates/entity_smt/reviews/3.jpeg",
            type: "image",
          },
          {
            link: "https://qoohoo-public.s3.ap-south-1.amazonaws.com/smt/reviews/videos/3.m3u8",
            type: "video",
          },
          {
            link: "/assets/templates/entity_smt/reviews/4.jpeg",
            type: "image",
          },
          {
            link: "https://qoohoo-public.s3.ap-south-1.amazonaws.com/smt/reviews/videos/4.m3u8",
            type: "video",
          },
          {
            link: "/assets/templates/entity_smt/reviews/5.jpeg",
            type: "image",
          },
          {
            link: "https://qoohoo-public.s3.ap-south-1.amazonaws.com/smt/reviews/videos/5.m3u8",
            type: "video",
          },
          {
            link: "/assets/templates/entity_smt/reviews/6.jpeg",
            type: "image",
          },
          {
            link: "/assets/templates/entity_smt/reviews/7.jpeg",
            type: "image",
          },
        ],
      },
    },
    {
      type: "VIDEO",
      data: {
        title: "YouTube videos",
        video_url: "https://www.youtube.com/embed/sgE7aqZrR5A",
        buttonLabel: "Subscribe now for free course",
        buttonIcon: "/assets/templates/entity_smt/youtube.svg",
        buttonLink: "https://www.youtube.com/@smtstockmarkettoday",
      },
    },
    {
      type: "CREATOR_INTRO",
      id: "about-us",
      data: {
        title: "About Us",
        name: "SMT™ - SEBI Registered Investors",
        photo_url: "/assets/templates/entity_smt/creator-profile.png",
        description: `
        SMT™ Academy is technical analysis firm created to provide unbiased stock/share market technical trading tips or trading analysis with the aim of protecting the innocent traders who invest their hard earned money in the rich man’s game, i.e. stock market, without any basic knowledge and not knowing the nuances of stock market game, especially in the area of option trading.
    `,
        social_links: [
          {
            label: "Instagram",
            icon: "/assets/templates/entity_smt/instagram.svg",
            link: "https://www.instagram.com/smt_sebi_registered/",
          },
          {
            label: "YouTube",
            icon: "/assets/templates/entity_smt/youtube.svg",
            link: "https://www.youtube.com/c/SMTSEBIRegistered",
          },
          {
            label: "Telegram",
            icon: "/assets/templates/entity_smt/telegram.svg",
            link: "https://telegram.me/SMTStockMarketToday",
          },
          {
            label: "Medium",
            icon: "/assets/templates/entity_smt/medium.svg",
            link: "https://smtstockmarkettoday.medium.com/",
          },
        ],
      },
    },
    {
      type: "BLOGS",
      data: {
        title: "Blogs",
        buttonLabel: "See more",
        buttonLink: "https://smtstockmarkettoday.medium.com/",
        arrowIcon: "/assets/templates/entity_smt/arrow.svg",
        items: [
          {
            image: "/assets/templates/entity_smt/blog-1.png",
            title: "Master the Markets with These Advanced Top 10 Options Trading Strategies",
            description: `Discover the top 10 options trading strategies that can help you master the markets and increase your chances of success. From basic to advanced techniques, this blog covers it all`,
            url: "https://smtstockmarkettoday.medium.com/master-the-markets-with-these-advanced-top-10-options-trading-strategies-2933d64a71c2"
          },
          {
            image: "/assets/templates/entity_smt/blog-2.png",
            title: "Best Investing Themes for Year 2023 to 2030",
            description: `Looking for the best investing themes to guide your portfolio in the coming years? Explore the top investment themes that are set to dominate the market from 2023 to 2030 and learn how to position your portfolio for success`,
            url: "https://smtstockmarkettoday.medium.com/best-investing-themes-for-year-2023-to-2030-fc7e2f27caf0"
          },
          {
            image: "/assets/templates/entity_smt/blog-3.png",
            title: "Derivatives Trading 101: A Comprehensive Glossary of Key Terms",
            description: `We provide a comprehensive guide to the basics of derivatives trading, including futures, options, and swaps. Learn about the key concepts, strategies, and risks involved`,
            url: "https://smtstockmarkettoday.medium.com/derivatives-trading-101-a-comprehensive-glossary-of-key-terms-5a837a38fe67"
          },
        ],
      },
    },
    {
      type: "FAQ",
      data: [
        {
          q: "What is the difference between Stock and Share?",
          a: "A stock and a share are essentially one and the same. They both represent a part of the capital of a joint stock company. In India it was always called shares whereas in the US they have been referred to as stocks. They essentially mean the same thing.",
        },
        {
          q: "What Instruments Are Traded In The Stock Markets?",
          a:
            "The stock market trades equity shares of various listed companies. In addition, the markets also trade" +
            " other instruments like index futures, index options, stock futures, stock options, VIX futures etc. Both equity and derivative products are traded in stock markets.",
        },
        {
          q: "Where Do I Find Stock Related Information?",
          a: `There are various sources for finding stock related information like the newspaper, websites, company annual reports etc. <a href="https://telegram.me/SMTStockMarketToday" style="font-weight:bold;color:#384AF1;" target="_blank">SMTStockMarketToday</a> also provide a plethora of rich stock market content and analytics on the website itself.`,
        },
        {
          q: "What Are The Factors That Determine The Price Of The Stock",
          a: "Price of the stock is normally determined by growth prospects and the profitability of the company. Normally, based on the attractiveness of the stock a P/E ratio is assigned by the market. Stock prices also depend on demand and supply as well as news flows.",
        },
        {
          q: "How Would You Choose Stock For Your Portfolio?",
          a: "You choose stock by screening the stocks in the market on profitability, risk, valuations etc. Such analytics are available on the Tradebulls website for traders to easily create a portfolio. It is always advisable to check with your RM or analyst before taking portfolio decisions.",
        },
        {
          q: "When Is The Best Time To Buy/Sell Share And Maximize Profit?",
          a: "There is nothing like a best time to buy the share because it depends on your trading / investment perspective. Traders must buy when the downside risk is the lowest and investors must buy when the upside potential is highest.FAQs",
        }
      ],
    },
    {
      type: "SKILLSET",
      data: {
        title: "Our skill sets",
        items: [
          {
            label: "Intraday Trades",
            percentage: 98,
          },
          {
            label: "Jackpot call",
            percentage: 90
          },
          {
            label: "Bank Nifty Trades",
            percentage: 95
          },
          {
            label: "Nifty Intraday Trades",
            percentage: 97
          },
          {
            label: "F&O Trades",
            percentage: 93
          },
        ]
      }
    }
  ],
};

export default entity_smt;
