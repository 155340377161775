const entity_BL0GEOJK = {
  settings: {
    checkout: {
      custom_success_note: `Dear {customer_name}, you will be redirected to join FREE whatsapp community`,
      redirect_url: "https://stockmarketedges.com/oto/"
    }
  },
  redirection_on_success: "https://stockmarketedges.com/oto/",
};

export default entity_BL0GEOJK;
