import {QOOHOO_WEBSITE_DOMAIN} from "@qoohoo/common/constants/env";

const entity_0RY2AJUU = {
  seo: {
    title: 'Daily Discipline is back • Vimal Singh Rathore',
    description: `We created magic with our discipline based approach towards exam & life. Daily Discipline impacted every aspect of our life - whether it's just one question per day, simple & to the point answers, daily walk & yoga - everything seems falling in place. Life is a bigger exam, big stage. I tried to restart this initiative a few months back but failed. I am sorry for that. But this time, it's just a Whatsapp group & a telegram channel, depending on where you feel more comfort. We will focus on daily small tasks, rituals to progress in our energy, focus, confidence and approach towards the life (& whichever exam at hand). Let's begin.`,
    image: `${QOOHOO_WEBSITE_DOMAIN}/assets/templates/template2/og-img.png`,
    url: 'https://vimalsinghrathore.com'
  },
  settings: {
    checkout: {
      redirect_url: `${QOOHOO_WEBSITE_DOMAIN}/channels/0ry2ajuu`,
    },
    customDomain: "https://vimalsinghrathore.com"
  },
  template: 'template2',
  sections: [
    {
      type: 'HERO',
      data: {
        title: 'Daily Discipline is back!',
        description: "We created magic with our discipline based approach towards exam & life. Daily Discipline impacted every aspect of our life - whether it's just one question per day, simple & to the point answers, daily walk & yoga - everything seems falling in place. Life is a bigger exam, big stage. I tried to restart this initiative a few months back but failed. I am sorry for that. But this time, it's just a Whatsapp group & a telegram channel, depending on where you feel more comfort. We will focus on daily small tasks, rituals to progress in our energy, focus, confidence and approach towards the life (& whichever exam at hand). Let's begin.",
        paymentButtonLabel: 'I pledge to start today',
        creatorInfo: {
          name: 'Vimal Singh Rathore',
          bio: ['Built two multi-million dollar startups in less than 3 years',
            'Teacher & Traveller at heart',
            'Here to share my learnings with you']
        },
        creatorImgUrl: '/assets/templates/template2/creator-img-main.png',
        features: [
          {
            icon: '/assets/templates/template2/icon-calendar.svg',
            label: 'Always ON',
          },
          {
            icon: '/assets/templates/template2/icon-message.svg',
            label: 'Group interaction'
          },
          {
            icon: '/assets/templates/template2/icon-video.svg',
            label: 'Live interaction'
          },
          {
            icon: '/assets/templates/template2/icon-telegram.svg',
            label: 'Telegram group'
          }
        ]
      }
    },
    {
      type: 'FACILITIES',
      data: {
        facilities: [
          {
            icon: "/assets/templates/template2/icon-calendar.svg",
            description: "Always ON",
            bgColor: "#FFFBF6",
            borderColor: "#EDAD73"
          },
          {
            icon: "/assets/templates/template2/icon-message.svg",
            description: "Group interaction",
            bgColor: "#F8FFF6",
            borderColor: "#72DE7D"
          },
          {
            icon: "/assets/templates/template2/icon-video.svg",
            description: "Live interaction",
            bgColor: "#FFF6F6",
            borderColor: "#F98585"
          },
          {
            icon: "/assets/templates/template2/icon-telegram.svg",
            description: "Telegram group",
            bgColor: "#F6FCFF",
            borderColor: "#85C1F9"
          },
        ]
      }
    },

    {
      type: 'ABOUT',
      data: {
        title: 'About This Initiative',
        video_src: { src: "https://youtu.be/DoUAob5RWfY", type: "video/youtube" }
      }
    },
    {
      type: 'FEATURES',
      data: {
        title: 'Focus',
        items: [
          "Goal Setting",
          "Learn importance of discipline by various activities & group discussions",
          "Measuring progress - You can't improve what you don't measure",
          "Most useful candlesticks and their uses",
          "Free From Yoga & Meditation",
          "Focus, Enthusiasm & Energy"
        ]
      }
    },
    {
      type: 'CUSTOMER_REVIEWS',
      data: {
        title: 'Here\'s what our learners say',
        images: [
          '/assets/templates/template2/testimonial-1.png',
          '/assets/templates/template2/testimonial-2.png',
          '/assets/templates/template2/testimonial-3.png',
          '/assets/templates/template2/testimonial-4.png',
          '/assets/templates/template2/testimonial-5.png'
        ]
      }
    },
    {
      type: 'CREATOR_INTRO',
      data: {
        title: 'Meet your Creator',
        name: 'Vimal Singh Rathore',
        photo_url: '/assets/templates/template2/creator-img.png',
        description:
          `
        <p className="text-[#3A3D40] font-semibold">
          Founder & CEO at qoohoo <br/>
          Founder, Coursavy (acquired by Unacademy)
        </p>
        `,
        social_links: [
          {
            icon: '/assets/social-icons/yt-coloured.svg',
            url: 'https://www.youtube.com/@VimalSinghRathore',
            alt: "youtube icon"
          },
          {
            icon: '/assets/social-icons/linkedin-coloured.svg',
            url: 'https://www.linkedin.com/in/vimalsinghrathore/',
            alt: "linkedin icon"
          }
        ]
      }
    },
  ]
};

export default entity_0RY2AJUU;