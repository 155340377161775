const entity_pt4dar5o = {
  settings: {
    checkout: {
      custom_success_note: `Hi {customer_name}, I am happy you chose SMT SEBI registered analyst for your financial growth`,
      post_payment_form: {
        title: 'Fill your details',
        description: 'It is mandatory to fill this form to ensure that the service is provided only to the verified users',
        order: ["pan_number", "state"],
        show_secured_banner: true
      }
    }
  },
};

export default entity_pt4dar5o;