const entity_thefitfoodietravels_meeting = {
    settings: {
        checkout: {
          theme: {
            brand_color: "#420C4C",
            background_color: "#E5DDF8"
          }
        },
      }
}

export default entity_thefitfoodietravels_meeting;