import { QOOHOO_WEBSITE_DOMAIN } from "@qoohoo/common/constants/env";

const entity_pd1bw5z6 = {
  settings: {
    checkout: {
      pre_payment_form: {
        order: ["email"],
      }
    },
  }
};

export default entity_pd1bw5z6;
