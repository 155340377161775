import entity_smt_footer from "./footers/entity_smt_footer";

const entity_smt_reports = {
  seo: {
    title: "SMT™ • Learn trade from SEBI registered investors only",
    description: `SMT™ Academy is technical advisory firm created to provide unbiased stock/share market technical trading tips or trading advisory with the aim of protecting the innocent traders who invest their hard earned money in the rich man’s game, i.e. stock market, without any basic knowledge and not knowing the nuances of stock market game, especially in the area of option trading.`,
    image: "/assets/templates/entity_smt/og-img.png",
  },
  template: "template9",
  navLinks: [
    {
      label: "Performance Reports",
      url: "/performance",
    },
    {
      label: "Investor Charter",
      url: "/investor-charter",
    },
  ],
  footer: entity_smt_footer,
  settings: {
  }
};

export default entity_smt_reports;
