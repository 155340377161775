import styles from "components/templates/template11/index.module.css";
import { qoohooWebsiteDomain } from "constants/domain";

const entity_bunchoftraders = {
  seo: {
    title: "BunchofTraders • Get Daily Free Sure Shot Intra Day Calls",
    description: `BunchofTraders is the finest stock market community that delivers you with best training & guidance for stock market in India. It is an initiative for the youthful era who are enthusiastic on making more, willing to step forward, and want to be free from financial crises. We provide Intraday & day trading calls on Equity, Bank Nifty, Stock Options, Future Options, Commodity, Technical Analysis and Fundamentals Analysis`,
    image: `${qoohooWebsiteDomain}/assets/templates/entity_bunchoftraders/OG.png`,
  },
  template: "template11",
  navLinks: [
    {
      label: `Telegram payments`,
      url: `${qoohooWebsiteDomain}/channels/premium-members-116psckw`,
    },
    {
      label: "Why choose us",
      url: "#why-choose-us",
    },
  ],
  meta: [{
    name:'facebook-domain-verification',
    content: "gl7er1vf11gaxaubd3kqyoxhr3lq0f"
  }],
  settings: {
    customDomain: "https://bunchoftraders.com",
    integrations: {
      fb_pixel: {
        FB_PIXEL_IDS: ["604320927831735"],
      },
    },
  },
  sections: [
    {
      type: "HERO",
      data: {
        title: `GET DAILY <span class="${styles['highlight-translucent']}">FREE SURE SHOT</span> INTRA DAY CALLS`,
        bg_image: "/assets/templates/entity_bunchoftraders/hero-bg.webp",
        description:`AND MAKE CONSISTENT PROFITS IN THE MARKET`,
        button: {
          label: `Join Telegram for FREE`,
          link: "https://telegram.me/+-Cj3RoFFCEg4ZWE1",
        },
        sendIcon: "/assets/templates/entity_bunchoftraders/icon-send.svg",
        features: [
          {
            icon: "/assets/templates/entity_bunchoftraders/icon-shield.svg",
            label: `JOIN`,
            description: `Join to access signals with more than 90% accuracy.`,
          },
          {
            icon: "/assets/templates/entity_bunchoftraders/icon-users.svg",
            label: `COPY`,
            description: `Simply copy the signals given by our professional traders.`,
          },
          {
            icon: "/assets/templates/entity_bunchoftraders/icon-trades.svg",
            label: `TRADE`,
            description: `Trade like a pro with our unbeatable accuracy.`,
          },
          {
            icon: "/assets/templates/entity_bunchoftraders/icon-growth.svg",
            label: `PROFIT`,
            description: `Make highly impressive returns with low-risk investments.`,
          },
        ],
      },
    },
    {
      type: "ABOUT",
      data: {
        subtitle: `WELCOME TO`,
        title: `<span class="${styles['highlight']}">BUNCH OF TRADERS</span> COMMUNITY`,
        description: `BunchofTraders is the finest stock market community that delivers you with best training & guidance for stock market in India. It is an initiative for the youthful era who are enthusiastic on making more, willing to step forward, and want to be free from financial crises. We provide Intraday & day trading calls on Equity, Bank Nifty, Stock Options, Future Options, Commodity, Technical Analysis and Fundamentals Analysis`,
      },
    },
    {
      type: "BENEFITS",
      data: {
        title: "PREMIUM MEMBER BENEFITS",
        tickIcon: "/assets/templates/entity_bunchoftraders/icon-tick.svg",
        items: [
          "Options Trading",
          "Advanced Watchlist",
          "Daily 1 to 2 Trades with right entry & exit",
          "All Trades One by One with full guidance & support",
          "Special Hero or Zero Jackpot Call on Expiry Day"
        ],
      },
    },
    {
      type: "FEATURES",
      id: "why-choose-us",
      data: {
        title: "WHY CHOOSE US?",
        items: [
          {
            icon: "/assets/templates/entity_bunchoftraders/features/icon-tool.svg",
            label: "WE DO THE HARD WORK",
            description: "Our team of professional traders scan the markets 24/7. We analyse and decrypt the markets and economies for you. All of us take the same trades. All of us make money!"
          },
          {
            icon: "/assets/templates/entity_bunchoftraders/features/icon-clock.svg",
            label: "WE SAVE YOUR TIME",
            description: "We spend hours in front of charts and news channel for you so that you can spend that time elsewhere, making money with your job or spending time with your family.",
          },
          {
            icon: "/assets/templates/entity_bunchoftraders/features/icon-dollar.svg",
            label: "WE SAVE YOUR MONEY",
            description: "Trading in the Forex market takes years of experience which includes losing a lot of money in the process. We trade our expertise to save you from the losses."
          },
          {
            icon: "/assets/templates/entity_bunchoftraders/features/icon-layer.svg",
            label: "WE ARE CONSISTENT",
            description: "Consistency is the name of the game. We are disciplined & patient traders and we know what we're doing. As a result we've always come out profitable unlike other services."
          },
          {
            icon: "/assets/templates/entity_bunchoftraders/features/icon-check-circle.svg",
            label: "UNBEATABLE ACCURACY",
            description: "We hold an accuracy of more than 90% which allows us to have a very little drawdown and maximum profits. Give our free Telegram channel a visit to verify our results.",
          },
          {
            icon: "/assets/templates/entity_bunchoftraders/features/icon-watch.svg",
            label: "24/7 SUPPORT",
            description: `You can message us anytime of the day with any of your queries. We will get back to you in the shortest period of time. We are always there to help.`
          }
        ],
        button: {
          label: "Join for free",
          link: "https://telegram.me/+-Cj3RoFFCEg4ZWE1",
        },
      },
    },
    {
      type: "CUSTOMER_REVIEWS",
      data: {
        title: `HERE'S WHAT <span class="${styles['highlight']}">OUR LEARNERS SAY</span>`,
        subtitle: "We have a happy client of 35k. Around hundreds of clients earn in thousands with the tips we provide daily.",
        reviews: [
          {
            link: "/assets/templates/entity_bunchoftraders/reviews/1.webp",
            type: "image",
          },
          {
            link: "/assets/templates/entity_bunchoftraders/reviews/2.webp",
            type: "image",
          },
          {
            link: "/assets/templates/entity_bunchoftraders/reviews/3.webp",
            type: "image",
          },
          {
            link: "/assets/templates/entity_bunchoftraders/reviews/4.webp",
            type: "image",
          },
          {
            link: "/assets/templates/entity_bunchoftraders/reviews/5.webp",
            type: "image",
          },
          {
            link: "/assets/templates/entity_bunchoftraders/reviews/6.webp",
            type: "image",
          },
          {
            link: "/assets/templates/entity_bunchoftraders/reviews/7.webp",
            type: "image",
          },
          {
            link: "/assets/templates/entity_bunchoftraders/reviews/8.webp",
            type: "image",
          },
          {
            link: "/assets/templates/entity_bunchoftraders/reviews/9.webp",
            type: "image",
          },
        ],
      },
    },
    {
      type: "FAQ",
      data: {
        items:[
          {
            q: "What is the difference between Stock and Share?",
            a: "A share and a stock are essentially the same thing. They both represent a portion of a joint stock company's capital. In India, it was usually referred to as shares, whereas in the US, it was always called stocks. They both convey essentially the same meaning.",
          },
          {
            q: "What Instruments Are Traded In The Stock Markets?",
            a: "Equity shares of various publicly traded corporations are traded on the stock market. Additionally, the markets also deal in other financial instruments like VIX futures, stock futures, stock options, index futures, and index options. In stock markets, both equities and derivative items are traded.",
          },
          {
            q: "Where Do I Find Stock Related Information?",
            a: "Information on stocks can be found in a variety of places, including the newspaper, websites, corporate annual reports, etc. On their websites, brokerage firms like Tradebulls offer a wealth of in-depth stock market analytics.",
          },
          {
            q: "What Are The Factors That Determine The Price Of The Stock",
            a: "Growth potential and the company's profitability typically decide the stock price. A P/E ratio is often provided by the market based on how appealing the stock is. Demand, supply, and news flow are other factors that affect stock prices.",
          },
          {
            q: "How Would You Choose Stock For Your Portfolio?",
            a: "You select a stock by analysing the market's stocks for profitability, risk, valuation, and other factors. Traders can easily build a portfolio using these analytics, which are accessible on the Tradebulls website. When making portfolio decisions, it is usually advisable to consult your RM or analyst.",
          },
          {
            q: "When Is The Best Time To Buy/Sell Share And Maximize Profit?",
            a: "As it depends on your trading or investment perspective, there is no such thing as the ideal moment to purchase a share. Investors must purchase when the upward potential is greatest, and traders must buy when the downside risk is the lowest.",
          },
        ],
        button: {
          label: "Join for free",
          link: "https://telegram.me/+-Cj3RoFFCEg4ZWE1",
        },
      },
    },
  ],
};

export default entity_bunchoftraders;
