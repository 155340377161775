import { QOOHOO_WEBSITE_DOMAIN } from "@qoohoo/common/constants/env";

const entity_thefitfoodietravels = {
  seo: {
    title: "Deepika • The fit foodie travels",
    description: "Time to get stronger, fitter and healthier! 💪",
    image: `${QOOHOO_WEBSITE_DOMAIN}/assets/templates/template5/og-img.png`,
    url: "https://thefitfoodietravels.com",
  },
  settings: {
    customDomain: "https://thefitfoodietravels.com",
  },
  template: "template5",
  navLinks: [
    /*
    {
      label: "Programs",
      url: "#telegram-payments",
    },
    {
      label: "Retreats",
      url: "#telegram-payments",
    },
*/
    {
      label: "Blogs",
      openInNewTab: true,
      url: "https://blog.thefitfoodietravels.com/",
    },
    /*
    {
      label: "Contact Us",
      url: "#telegram-payments",
    },
*/
  ],
  sections: [
    {
      type: "HERO",
      data: {
        title: `<span style="color:#FFDD6F" >Time to get</span> stronger, fitter and healthier! 💪`,
        description: `<p>Join the revolution and start your fitness journey today!</p>`,
        buttonLabel: "View all courses",
        buttonLink: "#courses",
        featureImgUrl: '/assets/templates/template5/feature-img.png'
      },
    },
    {
      type: "FEATURES",
      data: {
        title: "What will you learn?",
        items: [
          {
            icon: "/assets/templates/template5/feature-icon-new.png",
            label: "Over 100+ workouts with 500+ happy customers",
          },
          {
            icon: "/assets/templates/template5/feature-icon-new.png",
            label: "Exercise that fits your schedule",
          },
          {
            icon: "/assets/templates/template5/feature-icon-new.png",
            label: "Multiple programs & calendars",
          },
        ],
        paymentButtonLabel: "Checkout courses",
      },
    },
    {
      type: "COURSES",
      id: "courses",
      data: {
        title: "Checkout my courses",
        description:
          "Increase your endurance, strength and sports performance! You can chose between beginner or advanced levels, bodyweight training and strength training, or a combination of both! Be more confident, stronger and in your best shape ever!",
        paymentButtonLabel: "Join Now",
      },
    },
    {
      type: "BOOTCAMPS",
      data: {
        title: "Bootcamps",
        description:
          "Increase your endurance, strength and sports performance! You can chose between beginner or advanced levels, bodyweight training and strength training, or a combination of both! Be more confident, stronger and in your best shape ever!",
        paymentButtonLabel: "Get in touch",
        items: [
          {
            image: "/assets/templates/template5/course-1.png",
            title: "Beginner's Guide to Strength Training",
            price: "₹ 1,000",
          },
          {
            image: "/assets/templates/template5/course-2.png",
            title: "Beginner's Guide to Strength Training",
            price: "₹ 1,000",
          },
          {
            image: "/assets/templates/template5/course-3.png",
            title: "Beginner's Guide to Strength Training",
            price: "₹ 1,000",
          },
        ],
      },
    },
    {
      type: "RETREATS",
      data: {
        title: "The Fitreats",
        description:
          "Join our retreats for fitness, food and loads of exploration! Organized at multiple locations through the year, The Fitreats are all about fun, relaxation and making new friends. Check out our upcoming retreats and a book a place for yourself right now!",
        buttonLabel: "Get in touch",
        buttonLink: "https://thefitreats.com/",
        arrowIcon: "/assets/templates/template5/arrow.svg",
        image: "/assets/templates/template5/course-3.png",
      },
    },
    {
      type: "CUSTOMER_REVIEWS",
      id: "testimonials",
      data: {
        title: "Here's what our learners say",
        reviews: [
          {
            img: "/assets/templates/template4/reviews/1.png",
          },
          {
            img: "/assets/templates/template4/reviews/2.png",
          },
          {
            img: "/assets/templates/template4/reviews/3.png",
          },
          {
            img: "/assets/templates/template4/reviews/4.png",
          },
          {
            img: "/assets/templates/template4/reviews/5.png",
          },
          {
            img: "/assets/templates/template4/reviews/6.png",
          },
          {
            img: "/assets/templates/template4/reviews/7.png",
          },
          {
            img: "/assets/templates/template4/reviews/8.png",
          },
          {
            img: "/assets/templates/template4/reviews/9.png",
          },
          {
            img: "/assets/templates/template4/reviews/10.png",
          },
          {
            img: "/assets/templates/template4/reviews/11.png",
          },
          {
            img: "/assets/templates/template4/reviews/12.png",
          },
          {
            img: "/assets/templates/template4/reviews/13.png",
          },
        ],
      },
    },
    {
      type: "CREATOR_INTRO",
      data: {
        title: "Know your Trainer!",
        name: "Deepika Rao",
        bio: "Founder, The Fit Foodie Travels",
        photo_url: "/assets/templates/template4/creator.png",
        description: `
        <span><p>Hi! I am a <b>certified a Fitness trainer</b> a Fitness Nutrition specialist and a Animal Flow Instructor. I am extremely passionate about constantly learning and evolving. I believe fitness is a lifestyle and not just the way you look. I have been helping people achieve this goal for the last few years and now I would like to bring this to all of you! </p><br /><p><b>My programs are very flexible and can be done at home or at a Gym.</b> It doesn’t matter if you are a beginner or someone who finds working out as their second nature, you can use any of my programs to reach your fitness goals. <b><i>Let’s get fitter, stronger and healthier!</i></b></p></span>
      `,
      },
    },
    {
      type: "BLOGS",
      data: {
        title: "Blogs",
        buttonLabel: "See more",
        buttonLink: "https://blog.thefitfoodietravels.com/",
        items: [
          {
            image: "/assets/templates/template5/blog-1.png",
            title: "Ladakh – Part 2 Pangong Tso and Nubra Valley",
            description: `To read the first part go to To read the top things to do in Ladakh go to Day 4 The cab guy was late. I was getting...`,
            url: "https://blog.thefitfoodietravels.com/travelling-through-ladakh-part-2-pangong-tso-and-nubra-valley"
          },
          {
            image: "/assets/templates/template5/blog-2.png",
            title: "Chickpeas and Tofu Tikka Masala",
            description: `Who doesn’t love a good tikka masala? The chicken tikka masala is a dish that is known all over the world! Indian cuisine...`,
            url: "https://blog.thefitfoodietravels.com/chickpeas-and-tofu-tikka-masala"
          },
          {
            image: "/assets/templates/template5/blog-3.png",
            title: "The Truth About Fat Loss",
            description: `Fat loss! Fat percentage! Fat to muscle! Lose fat in 30 days! These are some of the most common headliners we will hear, see...`,
            url: "https://blog.thefitfoodietravels.com/the-truth-about-fat-loss"
          },
        ],
      },
    },
  ],
};

export default entity_thefitfoodietravels;
