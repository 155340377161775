const footer = {
  // is_white_labeled: true,
  theme: {
    bg_color: "#16191b",
    primary_color: "#fff",
  },
  org_description: `SMT™ (Stock Market Today) is led by a SEBI Registered Research Analyst. Our motto is to provide all required research about any stock or trading instruments which could be helpful to take an unbiased decision about trading.<br/><br/>
  Our research is based on technical, fundamental, trends, and other macro factors. As a SEBI Registered RA, our firm was created to provide unbiased stock/share market technical analysis, trading recommendations and trading analysis with the aim of protecting the innocent traders who invest their hard earned money in the rich man's game, i.e. stock market, without any basic knowledge and not knowing the nuances of stock market game, especially in the area of option trading.`,
  link_groups: [
    {
      title: 'ABOUT',
      links: [
        {
          label: 'About us',
          url: 'https://smtstockmarkettoday.com#about-us',
          openInNewTab: false,
        },
        {
          label: 'Contact us',
          url: 'https://telegram.me/SMTStockMarketToday'
        },
        {
          label: 'Services',
          url: 'https://smtstockmarkettoday.com#telegram-channels',
          openInNewTab: false,
        },
        {
          label: 'SCORES',
          url: 'https://www.scores.gov.in/scores/Welcome.html',
          openInNewTab: true,
        }
      ]
    },
    {
      title: 'BEST BROKERS',
      links: [
        {
          label: 'Zerodha',
          url: 'https://bit.ly/3mnBNUi'
        },
      ]
    },
  ],
  social_links: {
    title: 'FOLLOW US ON',
    links: [
      {
        icon: 'facebook',
        url: 'https://www.facebook.com/SMTStockMarketToday?mibextid=ZbWKwL'
      },
      {
        icon: 'instagram',
        url: 'https://www.instagram.com/smtstockmarkettoday?igshid=MDM4ZDc5MmU='
      },
      {
        icon: 'linkedin',
        url: 'https://www.linkedin.com/company/smtstockmarkettoday/'
      },
      {
        icon: 'youtube',
        url: 'https://www.youtube.com/@smtstockmarkettoday'
      },
    ]
  },
  tnc: {
    label: 'Terms & Conditions',
    url: '/terms'
  },
  copyright: '© Angel Research Academy 2023'
};

export default footer;