import entity_smt_footer from "./footers/entity_smt_footer";

const entity_smt_reports = {
  seo: {
    title: "SMT™ • Learn trade from SEBI registered research analysts",
    description: `SMT™ Academy is technical advisory firm created to provide unbiased stock/share market technical trading tips or trading advisory with the aim of protecting the innocent traders who invest their hard earned money in the rich man’s game, i.e. stock market, without any basic knowledge and not knowing the nuances of stock market game, especially in the area of option trading.`,
    image: "https://qoohoo.in/assets/templates/entity_smt/og-img.png",
  },
  template: "template7",
  navLinks: [
    {
      label: "Performance Reports",
      url: "/performance",
    },
    {
      label: "Investor Charter",
      url: "/investor-charter",
    },
  ],
  footer: entity_smt_footer,
  settings: {
    support_link: {
      label: "Join us",
      type: "telegram",
      link: "https://telegram.me/SMTStockMarketToday",
    },
    integrations: {},
  },
  sections: [
    {
      type: "HERO",
      data: {
        title: `<p>Performance</p>`,
        bg_image: "/assets/templates/entity_smt/hero-bg.jpeg",
      },
    },
    {
      type: "SECTION",
      data: {
        title: "Performance Report June-August 2023 (Future)",
        image: "/assets/templates/entity_smt/performance/june-aug_futures_2023.webp",
        buttonLabel: "Download",
        buttonLink: "/assets/templates/entity_smt/performance/june-aug_futures_2023.webp",
      },
    },
    {
      type: "SECTION",
      data: {
        title: "Performance Report June 2023",
        image: "/assets/templates/entity_smt/performance/june_report_2023.webp",
        buttonLabel: "Download",
        buttonLink: "/assets/templates/entity_smt/performance/june_report_2023.webp",
      },
    },
    {
      type: "SECTION",
      data: {
        title: "Performance Report May 2023",
        image: "/assets/templates/entity_smt/performance/may_report_2023.webp",
        buttonLabel: "Download",
        buttonLink: "/assets/templates/entity_smt/performance/may_report_2023.webp",
      },
    },
    {
      type: "SECTION",
      data: {
        title: "Performance Report April 2023",
        image: "/assets/templates/entity_smt/performance/april_report_2023.webp",
        buttonLabel: "Download",
        buttonLink: "/assets/templates/entity_smt/performance/april_report_2023.webp",
      },
    },
    {
      type: "SECTION",
      data: {
        title: "Performance Report March 2023",
        image: "/assets/templates/entity_smt/performance/march_report_2023.webp",
        buttonLabel: "Download",
        buttonLink: "/assets/templates/entity_smt/performance/march_report_2023.webp",
      },
    },
    {
      type: "SECTION",
      data: {
        title: "Performance Report February 2023",
        image: "/assets/templates/entity_smt/performance/feb_report.jpg",
        buttonLabel: "Download",
        buttonLink: "/assets/templates/entity_smt/performance/feb_report.jpg",
      },
    },
    {
      type: "SECTION",
      data: {
        title: "Performance Report December 2022- (Future)",
        image: "/assets/templates/entity_smt/performance/dec_smtp.webp",
        buttonLabel: "Download",
        buttonLink: "/assets/templates/entity_smt/performance/FUTURES PRO.pdf",
      },
    },
    {
      type: "SECTION",
      data: {
        title: "Performance Report December 2022",
        image: "/assets/templates/entity_smt/performance/dec_report.webp",
        buttonLabel: "Download",
        buttonLink: "/assets/templates/entity_smt/performance/DEC_SMTP.pdf",
      },
    },
    {
      type: "LINKS",
      data: {
        title: "Previous Month's Performance and Cash Reports",
        items: [
          {
            label: "Performance report June 2022",
            link: "/assets/templates/entity_smt/performance/JUN_SMTP.pdf",
            icon: "/assets/templates/entity_smt/performance/download.svg",
          },
          {
            label: "Performance report July 2022",
            link: "/assets/templates/entity_smt/performance/JUL_SMTP.pdf",
            icon: "/assets/templates/entity_smt/performance/download.svg",
          },
          {
            label: "Performance report Aug 2022",
            link: "/assets/templates/entity_smt/performance/AUG_SMTP.pdf",
            icon: "/assets/templates/entity_smt/performance/download.svg",
          },
          {
            label: "Performance report Sept 2022",
            link: "/assets/templates/entity_smt/performance/SEP_SMTP.pdf",
            icon: "/assets/templates/entity_smt/performance/download.svg",
          },
          {
            label: "Performance report Dec 2022",
            link: "/assets/templates/entity_smt/performance/DEC_SMTP.pdf",
            icon: "/assets/templates/entity_smt/performance/download.svg",
          },
        ],
      },
    },
  ],
};

export default entity_smt_reports;
