import dynamic from "next/dynamic";

import { isProd } from "@qoohoo/common/constants/isProd";
import configsProductionByUrl from "./production.configs";
import configsStagingByUrl from "./staging.configs";

const Template1 = dynamic(() =>
  import("../../../components/templates/template1")
);
const Template2 = dynamic(() =>
  import("../../../components/templates/template2")
);
const Template3 = dynamic(() =>
  import("../../../components/templates/template3")
);
const Template4 = dynamic(() =>
  import("../../../components/templates/template4")
);
const Template5 = dynamic(() =>
  import("../../../components/templates/template5")
);
const Template6 = dynamic(() =>
  import("../../../components/templates/template6")
);

const Template7 = dynamic(() =>
  import("../../../components/templates/template7")
);

const Template8 = dynamic(() =>
  import("../../../components/templates/template8")
);

const Template9 = dynamic(() =>
  import("../../../components/templates/template9")
);

const Template10 = dynamic(() =>
  import("../../../components/templates/template10")
);

const Template11 = dynamic(() =>
  import("../../../components/templates/template11")
);

const Template12 = dynamic(() =>
  import("../../../components/templates/template12")
);

const Template13 = dynamic(() => 
  import("../../../components/templates/template13")
);

export const getTemplateConfig = (url) => {
  if (!url) {
    return null;
  }
  
  const config = isProd
    ? configsProductionByUrl[url.toLowerCase()]
    : configsStagingByUrl[url.toLowerCase()];

  return config || null;
};

export const getTemplate = (templateId) => {
  let Template;
  if (templateId) {
    switch (templateId) {
      case "template1":
        Template = Template1;
        break;
      case "template2":
        Template = Template2;
        break;
      case "template3":
        Template = Template3;
        break;
      case "template4":
        Template = Template4;
        break;
      case "template5":
        Template = Template5;
        break;
      case "template6":
        Template = Template6;
        break;
      case "template7":
        Template = Template7;
        break;
      case "template8":
        Template = Template8;
        break;
      case "template9":
        Template = Template9;
        break;
      case "template10":
        Template = Template10;
        break;
      case "template11":
        Template = Template11;
        break;
      case "template12":
        Template = Template12;
        break;
      case "template13":
        Template = Template13;
        break;
    }
  } else {
    return null;
  }

  return Template;
};
