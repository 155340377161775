import { QOOHOO_WEBSITE_DOMAIN } from "@qoohoo/common/constants/env";

const entity_baazigarcricketexpert = {
  seo: {
    title: "Baazigar Cricket Expert",
    description: `BaazigarCricketExpert, a leading expert with decades of experience is now offering online fantasy sport course. Learn to make money by creating winning fantasy teams on apps like Dream11, MPL, and many more. Join now and take your fantasy sports game expertise to the next level!`,
    image: `${QOOHOO_WEBSITE_DOMAIN}/assets/templates/entity_baazigarcricketexpert/og-img.png`,
  },
  template: "template13",
  base_price: 20_000,
  settings: {
    checkout: {
      theme: {
        brand_color: "#E8483E",
      },
    },
  },
  sections: [
    {
      type: "HERO",
      data: {
        items:[
          "1 Year Tak Daily GL and SL Teams",
          "11 Years of Experience in Dream11",
          "India's Most Experienced Fantasy Cricket Expert",
          "4960+ Members Already Joined",
        ],
        button: {
          label: "Join for {discountedPrice} <span style='font-weight:600;font-size:83.333%;text-decoration:line-through'>{price}</span>",
        },
        video: {
          link: "https://www.youtube.com/watch?v=qzWmEAjTQds&ab_channel=FantasyCricketVIPCourse",
          type: "video/youtube"
        },
        icon: `${QOOHOO_WEBSITE_DOMAIN}/assets/templates/entity_baazigarcricketexpert/icons/tick.png`,
        discountLabel: "MEGA DISCOUNT of {discountPercentage}% OFF"
      },
    },
    {
      type: "BENEFITS",
      data: {
        title: "Extra Benefits of VIP course",
        description: `<p>Course ke saath 1 year tak daily minimum 3 matches ki well researched GL aur SL teams milegi jo pehle 5000 members course ko join karenge aur niche diye gaye saare bade tournaments cover honge</p>`,
        button: {
          label: "Join Now",
        },
      },
    },
    {
      type: "FEATURES_BAR",
      data: {
        items: [
          "INTERNATIONAL MATCHES",
          "PSL",
          "BBL",
          "IPL",
          "WORLD CUP",
          "CPL",
          "THE HUNDRED",
        ],
      },
    },
    {
      type: "FEATURES",
      data: {
        title: "What will you get?",
        items: [
          "Hum aapko Dream11 se Related Saari Tips,Tricks aur Secret Strategies Batayenge Jo Humare Jaise Dusre Experts Aap Se Chupate Hai",
          "Ek Aisi Secret Investment Strategy Batayi Jayegi Jisse Aapka Lifetime Dream11 Mein Profit Hi Hoga Kabhi Bhi Loss Nahi Ho Sakta",
          "Daily 3 Matches GL and SL Teams Ek Saal Tak",
          "Fantasy Cricket VIP Course in Video Lectures",
          "Lifetime Access of Video Lectures",
          "Course Dekhkar Aap Khud Expert Ban Jaoge aur Fantasy Cricket Mein Agar Aapko kuch Bhi Nahi Pata Fir Bhi Zero se Hero Ban Jaoge",
          "IPL | WORLD CUP | CPL | THE HUNDRED | BBL | PSL | INTERNATIONAL MATCHES",
        ],
        icon: `${QOOHOO_WEBSITE_DOMAIN}/assets/templates/entity_baazigarcricketexpert/icons/bat.png`,
        button: {
          label: "Join Now",
        },
      },
    },
    {
      type: "CUSTOMER_REVIEWS",
      data: {
        title: "Our learners love us ❤️",
        reviews: {
          videos: [
            {
              link: "https://youtu.be/CxDi6WBwxw0",
              type: "video/youtube",
            },
            {
              link: "https://youtu.be/xhu0flla7JQ",
              type: "video/youtube",
            },
            {
              link: "https://youtu.be/VHkgCuowRsY",
              type: "video/youtube",
            },
          ],
          text: [
            {
              review:
                "Maja a gaya bhai aisa course toh sabko lena chahiye sirf 4999 mein aap humein sikha bhi rahe ho aur ek saal tak teams bhi de rahe ho mujhe nhi lagta ke sirf 4999 mein itna sab kuch koi de sakta hai aapne video mein sahi bataya tha ki is course ki value toh crodo mein hai",
              author: "Suryadeep Singh",
            },
            {
              review:
                "Bazigar sir you are great pehle mujhe dream11 khelna aata hi nhi tha aur bohot loss hota tha ab aapke corse ki waja se daily ka 15000 ka profit nikal leta Hu aapki small league team se khel raha hu lagatar Jeet Raha hu aapki secret tips aur strategies jaanke ab mein bol sakta hu ki mein khud expert ban gaya hu",
              author: "Aniket Jaiswal",
            },
            {
              review:
                "Thank You Baazigar Bhai. Course Lene Se Pehle Socha Nahi Tha Itna Kuch Sikh Lunga. Ye Course Ek Investment Ki Tarah Hai Jiska Profit Mujhe Saalo Tak Hoga. Last Month Maine Aapki Wajah Se 2,30,000 Ka Profit Kiya, is Month ka Target 5,00,000 ka Hai aur Jis Tarah Aap Team de Rahe hai Lagta Hai 15-20 Din Mein Target Complete Hojayega",
              author: "Suman Mehra",
            },
            {
              review:
                "Apke course se gl team banana sikha aur 3 din pehle t10 ki gl mein 1st rank aaya aur 4.5 lakh jeeta. aap jo private telegram group mein hum members ko teams dete ho, usse daily badhiya winning hoti hai aur aapki investment strategy kamaal ki hai. 3 saal ho gaye Dream11 khelte, aajtak kisi ne aisi strategy nhi batayi. Thank you boss",
              author: "Prayag Roy",
            },
          ],
        },
        button: {
          label: "Join Now",
        },
        quoteIcon: `${QOOHOO_WEBSITE_DOMAIN}/assets/templates/entity_baazigarcricketexpert/icons/blockquote.svg`
      },
    },
  ],
};

export default entity_baazigarcricketexpert;
