import { QOOHOO_WEBSITE_DOMAIN } from "@qoohoo/common/constants/env";

const entity_5tnsxd1x = {
  seo: {
    title: "Women In Product | The Product Folks",
    description: `India’s largest community for product enthusiasts`,
    image: `${QOOHOO_WEBSITE_DOMAIN}/assets/templates/entity_tpf/og-img.png`,
  },
  template: "template12",
  navTitle: "Women in Product",
  settings: {
    checkout: {
      enable_new_checkout: true,
      theme: {
        brand_color: "#E27528",
      },
      pre_payment_form: {
        order: ["email"],
      }
    },
  },
  data: {
    background_image: "/assets/templates/entity_tpf/bg.svg",
    membership_icon: "/assets/templates/entity_tpf/calendar.svg",
    membership_period: "ANNUAL MEMBERSHIP",
    description: {
      title: `<p>Membership at WIP includes:</p>`,
      icon: "/assets/templates/entity_tpf/check.svg",
      items: [
        "1:1 Mentorship with Product experts",
        "Women-only Offline networking events",
        "Interactive Masterclass",
        "WIP Newsletter",
        "Round-Table Discussions",
        "On Air with Product Leaders",
        "Candid Coffee conversations",
      ],
    },
    review_title: "What community says about us",
    reviews: [
      {
        name: "Kopal Seth",
        designation: "Client Manag. Associate",
        company: "Therapize",
        image: "/assets/templates/entity_tpf/reviews/1.png",
        review:
          "In usual TPF style, it has been so well organized and a successful curation of diverse and super interesting members. From the newsletters you'd actually look forward to, to the networking sessions and webinars where you actually get to know the members better through fun activities, it's just the enthusiastic community I'd hope it would be :)",
      },
      {
        name: "Ashima Biyani",
        designation: "Product Analyst",
        company: "Reliance Jio",
        image: "/assets/templates/entity_tpf/reviews/2.png",
        review:
          "WIP was one of the breakthroughs for me, an open space with the freedom to interact and share the struggles with some of the amazing women in product, to learn and grow at each and every stage, moreover, to be inspired by the fact that there are many females who share this dream of being recognized in this challenging and mind blowing field.",
      },
      {
        name: "Shemrin",
        designation: "Product Consultant",
        company: "Hexnode",
        image: "/assets/templates/entity_tpf/reviews/3.png",
        review: "It's been an amazing one month of getting acquainted with my fellow Cohort mates, sharing knowledge and resources. It's always a great feeling to be placed amongst an uplifting bunch of product enthusiasts."
      }
    ],
    creator: {
      name: "Women In Product | The Product Folks",
    },
    cta_button_text: "Proceed to pay",
  },
};

export default entity_5tnsxd1x;
