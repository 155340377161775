import entity_smt from "./production/entity_smt";
import entity_smt_investor from "./production/entity_smt_investor";
import entity_smt_reports from "./production/entity_smt_reports";
import entity_smt_terms from "./production/entity_smt_terms";
import entity_smt_compliance_form from "./production/entity_smt_compliance_form";
import entity_5BCP3VNH from "./production/entity_5BCP3VNH";
import entity_0RY2AJUU from "./production/entity_0RY2AJUU";
import entity_YXZ11MSY from "./production/entity_YXZ11MSY";
import entity_BL0GEOJK from "./production/entity_BL0GEOJK";

import entity_thefitfoodietravels from "./production/entity_thefitfoodietravels";
import entity_pavyhsio from "./production/entity_pavyhsio";
import entity_aaysrqb3 from "./production/entity_aaysrqb3";
import entity_supercheckout from "./production/entity_supercheckout";
import entity_kalakritikarishma from "./production/entity_kalakritikarishma";
import entity_bunchoftraders from "./production/entity_bunchoftraders";
import entity_5tnsxd1x from "./production/entity_5tnsxd1x";
import entity_T3PV769W from "./production/entity_T3PV769W";
import entity_thefitfoodietravels_meeting from "./production/entity_thefitfoodietravels_meeting";
import entity_baazigarcricketexpert from "./production/entity_baazigarcricketexpert";

const configsStagingByUrl = {
  "/channels/7eb6g1g1": {
    type: "channel",
    config: entity_5BCP3VNH,
  },
  "/channels/9h3a4k50": {
    type: "channel",
    config: entity_5BCP3VNH,
  },
  "/courses/srtkuucf": {
    type: "channel",
    config: entity_0RY2AJUU,
  },
  "/aseem_gupta_179": {
    type: "profile",
    config: entity_thefitfoodietravels,
  },
  "/aseem_pro": {
    type: "profile",
    config: entity_smt,
  },
  "/aseem_pro/investor-charter": {
    type: "profile",
    config: entity_smt_investor,
  },
  "/aseem_pro/performance": {
    type: "profile",
    config: entity_smt_reports,
  },
  "/aseem_pro/terms": {
    type: "profile",
    config: entity_smt_terms,
  },
  "/aseem_pro/sebi-compliance-form": {
    type: "profile",
    config: entity_smt_compliance_form,
  },
  "/channels/6ab0t2xr": {
    type: "channel",
    config: entity_YXZ11MSY,
  },
  "/channels/39xlxnyt": {
    type: "channel",
    config: entity_pavyhsio,
  },
  "/channels/rnne7dqu":{
    type: "channel",
    config: entity_BL0GEOJK,
  },
  "/channels/d25dhwso": {
    type: "channel",
    config: entity_aaysrqb3,
  },
  "/channels/n5ke2e14": {
    type: "channel",
    config: entity_supercheckout,
  },
  "/channels/53rdxzj3": {
    type: "channel",
    config: entity_supercheckout,
  },
  "/channels/963uzm9l": {
    type: "channel",
    config: entity_supercheckout,
  },
  "/channels/1cir1krx": {
    type: "channel",
    config: entity_supercheckout,
  },
  "/kartikey_chauhan": {
    type: "profile",
    config: entity_kalakritikarishma,
  },
  "/kartik_alt_261": {
    type: "profile",
    config: entity_bunchoftraders,
  },
  "/channels/mfyot0v8": {
    type: "channel",
    config: entity_5tnsxd1x,
  },
  "/premium-content/0lmgo6qm": {
    type: "premium-content",
    config: entity_T3PV769W,
  },
  "/book-slots/f1odh87i": {
    type: "one_to_one_meeting",
    config: entity_thefitfoodietravels_meeting,
  },
  "/book-slots/d22q1ezi": {
    type: "one_to_one_meeting",
    config: entity_thefitfoodietravels_meeting,
  },
  "/courses/ugsoodz4": {
    type: "course",
    config: entity_baazigarcricketexpert,
  }
};

export default configsStagingByUrl;
