import {QOOHOO_WEBSITE_DOMAIN} from "@qoohoo/common/constants/env";

const entity_YXZ11MSY = {
  seo: {
    title: "Deepika • The fit foodie travels",
    description: "Online group fitness training",
    image: `${QOOHOO_WEBSITE_DOMAIN}/assets/templates/template4/cover.png`,
    url: "https://thefitfoodietravels.com"
  },
  settings: {
    customDomain: "https://thefitfoodietravels.com",    
    checkout: {
      redirect_url: "https://docs.google.com/forms/d/e/1FAIpQLScV6M0dyFzk-A8qzVNKYTWGrcrQ1m6xoeEsi5kNNk8SvBZdmw/viewform?vc=0&c=0&w=1&flr=0",
    },
    payment: {
      redirection_on_success: 'https://docs.google.com/forms/d/e/1FAIpQLScV6M0dyFzk-A8qzVNKYTWGrcrQ1m6xoeEsi5kNNk8SvBZdmw/viewform?vc=0&c=0&w=1&flr=0'
    }
  },
  template: "template4",
  // channelUid: "YXE2HHWG",
  navLinks: [
/*
    {
      label: "Programs",
      url: "#telegram-payments",
    },
    {
      label: "Retreats",
      url: "#telegram-payments",
    },
*/
    {
      label: "Blogs",
      openInNewTab: true,
      url: "https://blog.thefitfoodietravels.com/",
    },
    {
      label: "Testimonials",
      openInNewTab: true,
      url: "#testimonials",
    },
/*
    {
      label: "Contact Us",
      url: "#telegram-payments",
    },
*/
  ],
  sections: [
    {
      type: "HERO",
      data: {
        title: `Online group fitness training`,
        description: `<p>
          Join this fun-filled session with me which emphasizes both on nutrition and workout. After successful enrolment you'd be receiving an onboarding call. We'd be discussing your goals and a personalized diet plan would be shared with you.
              </p>`,
        paymentButtonLabel: "Enroll Now",
        creatorInfo: {
          name: "",
          bio: "",
          image: "/assets/templates/template4/cover.png",
        },
        features: [
          {
            icon: "/assets/templates/template4/calendar.svg",
            label: `<p>Mon, Wed, Fri 7-8am</p>`,
          },
          {
            icon: "/assets/templates/template4/movie.svg",
            label: `<p>Multiple plans</p>`,
          },
          {
            icon: "/assets/templates/template4/message.svg",
            label: `<p>Live interaction</p>`,
          },
        ],
      },
    },
    {
      type: "FEATURES",
      data: {
        title: "What will you learn?",
        description:
          "The workout routine is divided into the following manner:",
        items: [
          {
            icon: "/assets/templates/template4/icons/1.png",
            label: `<p>3 live virtual group sessions would be conducted under my supervision</p>`,
          },
          {
            icon: "/assets/templates/template4/icons/2.png",
            label: `<p>Monthly analysis on your strength and progress</p>`,
          },
          {
            icon: "/assets/templates/template4/icons/4.png",
            label: `<p>During the non-training days, DIY workouts would be shared over WhatsApp/email</p>`,
          }
        ],
        paymentButtonLabel: "Enroll Now",
      },
    },
    {
      type: "CUSTOMER_REVIEWS",
      id: "testimonials",
      data: {
        title: "Here's what our learners say",
        reviews: [
          {
            img: "/assets/templates/template4/reviews/1.png",
          },
          {
            img: "/assets/templates/template4/reviews/2.png",
          },
          {
            img: "/assets/templates/template4/reviews/3.png",
          },
          {
            img: "/assets/templates/template4/reviews/4.png",
          },
          {
            img: "/assets/templates/template4/reviews/5.png",
          },
          {
            img: "/assets/templates/template4/reviews/6.png",
          },
          {
            img: "/assets/templates/template4/reviews/7.png",
          },
          {
            img: "/assets/templates/template4/reviews/8.png",
          },
          {
            img: "/assets/templates/template4/reviews/9.png",
          },
          {
            img: "/assets/templates/template4/reviews/10.png",
          },
          {
            img: "/assets/templates/template4/reviews/11.png",
          },
          {
            img: "/assets/templates/template4/reviews/12.png",
          },
          {
            img: "/assets/templates/template4/reviews/13.png",
          },
        ],
      },
    },
    {
      type: "CREATOR_INTRO",
      data: {
        title: "Know your Trainer!",
        name: "Deepika Rao",
        bio: "Founder, The Fit Foodie Travels",
        photo_url: "/assets/templates/template4/creator.png",
        description: `
        <span><p>Hi! I am a <b>certified a Fitness trainer</b> a Fitness Nutrition specialist and a Animal Flow Instructor. I am extremely passionate about constantly learning and evolving. I believe fitness is a lifestyle and not just the way you look. I have been helping people achieve this goal for the last few years and now I would like to bring this to all of you! </p><br /><p><b>My programs are very flexible and can be done at home or at a Gym.</b> It doesn’t matter if you are a beginner or someone who finds working out as their second nature, you can use any of my programs to reach your fitness goals. <b><i>Let’s get fitter, stronger and healthier!</i></b></p></span>
      `,
      },
    },
    {
      type: "FAQ",
      data: [
        {
          q: "What do I do when the program ends?",
          a: "You can reenrol and continue on your fitness journey to get stronger, fitter, healthier and happier!",
        },
        {
          q: "What does the program involve?",
          a: "This includes 4 weeks of, 5 days a week class-style workouts. You will be set with a number of goals to aim to complete each week.\nChallenge yourself and train side by side with Deepika as she guides you through workouts that will leave you feeling strong and accomplished!",
        },
        {
          q: "What equipment do I need?",
          a: "This only requires a pair of dumbbells. If you have more than one set available, Deepika rotates between a light, medium and heavy pair.",
        },
        {
          q: "What is Online group training and how does it work?",
          a: "Deepika’s Program fuses bodyweight, strength training, animal flow and mobility training together for a unique experience that will reshape your approach to fitness and assist with increasing your strength and muscular endurance.\nThis unique strength program provides you with the tools to learn mindfulness techniques to better connect your mind and body and redefine your “why” whilst completing your workout.\nThis is suitable for all fitness levels, from beginner to advanced and can be completed in your own home with minimal equipment.\nThe classes run for 45-55 minutes and are moderate to high intensity that includes classic weight training exercises that can be made harder by using heavier weights, or simplified by reducing the weight and focusing on bodyweight movement.\nYou also get a personalized diet plan.\n",
        },
      ],
      paymentButtonLabel: "Enroll Now",
    },
  ],
};

export default entity_YXZ11MSY;
