import entity_smt_footer from "./footers/entity_smt_footer";

const entity_smt_investor = {
  seo: {
    title: "SMT™ • Learn trade from SEBI registered investors only",
    description: `SMT™ Academy is technical advisory firm created to provide unbiased stock/share market technical trading tips or trading advisory with the aim of protecting the innocent traders who invest their hard earned money in the rich man’s game, i.e. stock market, without any basic knowledge and not knowing the nuances of stock market game, especially in the area of option trading.`,
    image: "https://qoohoo.in/assets/templates/entity_smt/og-img.png",
  },
  template: "template6",
  navLinks: [
    {
      label: "Investor Charter",
      url: "/investor-charter",
    },
  ],
  footer: entity_smt_footer,
  settings: {
    support_link: {
      label: "Join us",
      type: "telegram",
      link: "https://telegram.me/SMTStockMarketToday",
    },
    integrations: {},
  },
  sections: [
    {
      type: "HERO",
      data: {
        title: `<p>Investor Charter</p>`,
        bg_image: "/assets/templates/entity_smt/hero-bg.jpeg",
      },
    },
    {
      type: "SECTION",
      data: {
        title: "Vision and Mission Statements for investors",
        description: `<p>
          <b>Vision</b> – Invest with knowledge & safety.<br>
          <b>Mission</b> – Every investor should be able to invest in right investment products based on their needs, manage and monitor them to meet their goals, access reports and enjoy financial wellness.
          </p>`,
        items: [],
      },
    },
    {
      type: "SECTION",
      data: {
        title:
          "Details of business transacted by the Chartered Analyst with respect to the investors.",
        items: [
          "To publish research report based on the research activities of the CA.",
          "To provide an independent unbiased view on securities.",
          "To offer unbiased recommendation, disclosing the financial interests in recommended securities.",
          "To provide research recommendation, based on analysis of publicly available information and known observations.",
          "To conduct audit annually.",
        ],
      },
    },
    {
      type: "SECTION",
      data: {
        title:
          "Details of services provided to investors (No Indicative Timelines)",
        items: [
          "Disclosure to Clients",
          "To distribute research reports and recommendations to the clients without discrimination.",
          "To maintain confidentiality w.r.t publication of the research report until made available in the public domain.",
        ],
      },
    },
    {
      type: "SECTION",
      data: {
        title: "Details of grievance redressal mechanism and how to access it",
        items: [
          "In case of any grievance / complaint, an investor should approach the concerned research analyst and shall ensure that the grievance is resolved within 30 days.",
          "If the investor’s complaint is not redressed satisfactorily, one may lodge a complaint with SEBI on SEBI’s SCORES portal which is a centralized web-based complaints redressal system. SEBI takes up the complaints registered via SCORES with the concerned intermediary for timely redressal. SCORES facilitates tracking the status of the complaint.",
          "With regard to physical complaints, investors may send their complaints to: Office of Investor Assistance and Education, Securities and Exchange Board of India, SEBI Bhavan. Plot No. C4-A, ‘G’ Block, Bandra-Kurla Complex, Bandra (E), Mumbai - 400 051.",
        ],
      },
    },
    {
      type: "SECTION",
      data: {
        title:
          "Expectations from the investors (Responsibilities of investors)",
        sub_sections: [
          {
            description: `<p><b>Do’s</b></p>`,
            items: [
              "Always deal with SEBI registered Chartered Analyst.",
              "Check for SEBI registration number.",
              "Ensure that the Chartered Analyst has a valid registration certificate.",
              `Please refer to the list of all SEBI registered Chartered Analysts which is available on SEBI website in this <a target="_blank" href="https://www.sebi.gov.in/sebiweb/other/OtherAction.do?doRecognisedFpi=yes&intmId=14"><u>link</u></a>.`,
              "Always pay attention towards disclosures made in the research reports before investing.",
              "Pay your Chartered Analyst through banking channels only and maintain duly signed receipts mentioning the details of your payments.",
              "Before buying securities or applying in public offer, check for the research recommendation provided by your Chartered Analyst.",
              "Ask all relevant questions and clear your doubts with your Chartered Analyst before acting on the recommendation.",
            ],
          },
          {
            description: `<p><b>Don’ts</b></p>`,
            items: [
              "Do not provide funds for investment to the Chartered Analyst.",
              "Don’t fall prey to luring advertisements or market rumours.",
              "Do not get attracted to limited period discount or other incentive, gifts, etc. offered by Chartered Analyst.",
              "Do not share login credentials and password of your Trading and Demat Account with the Chartered Analyst.",
            ],
          }
        ]
      },
    },
    {
      type: "TABLE",
      data: {
        title: "Details of Complaints",
        description: `<p>Data for the month ending - February 2023</p>`,
      },
    },
    {
      type: "SECTION",
      data: {
        title: "Compliant redressal and scores process",
        description: `<p>Client’s queries / complaints may arise due to lack of understanding or a deficiency of service experienced by clients. Deficiency of service may include lack of explanation, clarifications, understanding which escalates into shortfalls in the expected delivery standards, either due to inadequacy of facilities available or through the attitude of staff towards client.</p>`,
        items: [
          `<p>Clients can seek clarification to their query and are further entitled to make a complaint in writing, orally or telephonically. An email may be sent to the Client Servicing Team on <a href='mailto:stocksmarkettoday@gmail.com'><u>stocksmarkettoday@gmail.com</u></a>. Alternatively, the Investor may call on <a href='tel:8262889149'><u>8262889149</u></a></p>`,
          `<p>A letter may also be written with their query/complaint and posted at the below mentioned address Angel Research Academy, Chandrapur Maharashtra, 442505</p>`,
          `<p>Clients can write to the principal officer at Vijay Laxmi <a href='mailto:stocksmarkettoday@gmail.com'><u>stocksmarkettoday@gmail.com</u></a> if the Investor does not receive a response within 10 business days of writing to the Client Servicing Team. The client can expect a reply within 10 business days of approaching research analyst.</p>`,
          `<p>In case you are not satisfied with our response you can lodge your grievance with SEBI at <a href='http://scores.gov.in'><u>http://scores.gov.in</u></a> or you may also write to any of the offices of SEBI. SCORES may be accessed thorough SCORES mobile application as well, same can be downloaded from below link: <a href='https://play.google.com/store/apps/details?id=com.ionicframework.sebi236330'><u>https://play.google.com/store/apps/details?id=com.ionicframework.sebi236330</u></a></p>`,
        ],
      },
    },
  ],
};

export default entity_smt_investor;
