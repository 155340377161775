import {QOOHOO_WEBSITE_DOMAIN} from "@qoohoo/kalakritikarishma/src/constants/env";

const entity_kalakritikarishma = {
  seo: {
    title: "Kalakriti by Karishma",
    description: `Welcome to my little world of Mandalas!`,
    image: `${QOOHOO_WEBSITE_DOMAIN}/assets/templates/entity_kalakritikarishma/OG.png`,
  },
  template: "template10",
  navTitle: "Kalakriti by Karishma",
  navLinks: [
    {
      label: "About",
      url: "#about",
    },
    {
      label: "Testimonials",
      url: "#testimonials",
    },
  ],
  socialLinks: [
    {
      icon: "/assets/templates/entity_kalakritikarishma/instagram.svg",
      url: "https://www.instagram.com/kalakriti.karishma/",
      alt: "instagram icon",
    },
    {
      icon: "/assets/templates/entity_kalakritikarishma/pinterest.svg",
      url: "https://www.pinterest.com/Karishma_Srivastava/",
      alt: "pinterest icon",
    },
    {
      icon: "/assets/templates/entity_kalakritikarishma/youtube.svg",
      url: "https://youtube.com/@KalakritibyKarishma",
      alt: "youtube icon",
    },
  ],
  settings: {
    customDomain: "https://kalakritikarishma.com",
  },
  sections: [
    {
      type: "HERO",
      data: {
        title: `Hi, Welcome to my little world of Mandalas! :)`,
        description: `I'm Karishma Srivastava, a Mandala Artist and Educator based in Gaya, India. I've been drawing Mandalas for four years now and have taught a large number of people from around the world. I offer Online Courses and Practice Sheets that help people experience the calming and therapeutic effects of this beautiful form of art`,
        button: {
          label: "View my offerings",
          link: "#offerings",
        },
        image: "/assets/templates/entity_kalakritikarishma/hero-image.webp",
        accentIcon: "/assets/templates/entity_kalakritikarishma/curve.svg",
        accentIconSmall: "/assets/templates/entity_kalakritikarishma/curve-sm.svg"
      }
    },
    {
      type: "OFFERINGS",
      id:"offerings",
      data: {
        title: "My offerings",
        banner: {
          title: "Interested in learning Mandalas and its various aspects?",
          description: "Fill out the form to learn more about the different Online Courses I offer.",
          button: {
            label: "Fill the Form",
            link: "https://docs.google.com/forms/d/1g6P9VuIp1q0GZU7cuVuQYi0TsqwH4wr9VaNoVZTBimE/viewform?fbclid=PAAaa4Titb-_AxCae5YJnhoCCT08DnwRVYd76oyiKYbLPG4A5Br5TbKq36RVY"
          },
        },
        items: [
          {
            title: "Courses",
            type: "CHANNELS",
            button: {
              label: "Join Now",
              icon: "/assets/templates/entity_kalakritikarishma/arrow.svg"
            },
          },
          {
            title: "Practice Sheets",
            type:"PREMIUM_CONTENTS",
            button: {
              label: "Unlock Now",
              icon: "/assets/templates/entity_kalakritikarishma/arrow.svg"
            },
          }
        ]
      },
    },
    {
      type: "CUSTOMER_REVIEWS",
      id: "testimonials",
      data: {
        title: "Kind words",
        items: [
          {
            customer: "Veenus Verma",
            review: `<p>Thank you Karishma. It was a very enriching experience with you. You were very patient in all classes. You never rushed me or hesitated to explain again n again...n you were very sweet with your reviews.I m glad I took these classes with you out of many other who r offering them on Instagram. Will stay connected n bother you in case of doubts</p>`,
          },
          {
            customer: "Tilaka Tatiane Carvalho",
            review: `<p>Karishmaji<br/>
            Thank you very much.<br/>
            It was a great experience to join this course.<br/>
            I have joined many different workshops before and I am myself a teacher. And I will tell you that you are doing a great work. Your methodology is very good and you take care of everyone very nicely.<br/>
            Really a very good teacher!<br/>
            Wishing you all the best,</p>`
          },
          {
            customer: "Jui Mhatre",
            review: `<p>Mam, it was a great experience with you. Thank you for enhancing my mandala drawing skills. I learnt a lot in this course. Your tips and techniques are really helpful to me for further mandalas. You are a very nice teacher. Your wonderful teaching made my learning easy and full of joy. I enjoyed your course very much. I once again thank you and request you guide me in my further Mandala arworks.</p>`,
          },
          {
            customer: "Sethu Ram",
            review: `<p>Its really wonderful time with you Karishma. Yes you are a great teacher and an effective communicator. Its my pleasure to met a creative genius like you with a good cause. Felt bad for not sharing the proper feedback at face to face. saw your dedication, patience and determination with your works and teachings in live manner. hope to see you offline once if possible. Thank you a lot.</p>`,
          },
          {
            customer: "Ishpreet",
            review: `<p>Thank you so much @Kalakriti by Karishma for such wonderful sessions My daughter learned a lot about this beautiful art and got to know about so many tips n tricks!<br/>
            So happy that you are her mentor</p>`,
          },
          {
            customer: "Arpita Katyal",
            review: `<p>Thank you so much Karishma, for an insightful session<br/>
            It has been an absolute delight knowing and learning the tricks of the trade from you! And your guidance on every aspect has been mind-blowing many many thanks once again! God bless you and keep inspiring us</p>`
          },
          {
            customer: "Kirti Khanage",
            review: `<p>Thank you so much @Kalakriti by Karishma for such amazing sessions.. Loved every second.. Very memorable Class totally Mind Blown Once Again Very valuable inputs .. Will surely work on everything and submit asap</p>`,
          },
          {
            customer: "Pooja Vasu",
            review: `<p>Hello Karishma, It was really fantastic workshop. I am so grateful that I choose you as my Mandala teacher. On first day of course I didn't imagine that I will learn this much. You changed my perceptive toward Mandalas.<br/>
            I will be bless if you will be my mentor for life. There is a lot to learn from YOU. Thank you so so much</p>`,
          }
        ],
        button: {
          label: "Checkout my offerings",
          link: "#offerings",
        },
      },
    },
    {
      type: "ABOUT",
      id: "about",
      data: {
        title: "Read about my journey",
        image: "/assets/templates/entity_kalakritikarishma/about-image.webp",
        description: `<p>As an avid lover of Art & Design, I completed my Bachelor's in Fashion and Lifestyle Accessories Design from NIFT, Hyderabad in 2013. After my graduation, I worked as a Jewellery Designer and Merchandiser in the diamond industry, Mumbai for about 5 years.
        <br/>
        Due to some unavoidable circumstances, I had to leave the job I was passionate about.
        <br/><br/>
        My love for my work helped me hold pen and paper once again after almost 7-8 months.
        <br/>
        I discovered my love for Mandalas when I actually drew one in Oct 2018 without knowing that it's an Ancient form of Art.
        <br/>
        My first mandala was without the help of any geometry tools but it acted as a great stress-reliever for me. I found the process so relaxing that I started drawing it quite often.
        <br/><br/>
        A few days later on Nov 23, 2018, I created a page on Instagram to share my works. Little did I know then that the Art-form would pave a way for so many exciting opportunities a year ahead.
        <br/>
        I announced my first workshop in Hyderabad in Sept 2019 for which I got an amazing response. Since then I got several other opportunities to conduct workshops, judge contests in different cities across the country.
        <br/><br/>
        Due to the pandemic in 2020, I had to switch to online classes from physical workshops. I launched my first Online Course in June 2022. Since then, it’s only got better by the grace of God.
        <br/><br/>
        As a mentor, I’m able to bring a positive change in the lives of people. The experience so far has been enriching, rewarding and fulfilling.
        <br/>
        I’m living my passion and purpose today. Whatever I am and all that I hope to be, I owe it all to my parents and all those who have supported me in one way or the other.
        <br/><br/>
        I have nothing but gratitude. :)
        <br/>
        The journey has just begun...
        </p>`,
      },
    },
    {
      type: "COLLAGE",
      data: {
        title: "My lovely students and their work",
        items: [
          {
            type: "IMAGE",
            url: "/assets/templates/entity_kalakritikarishma/collage1.webp",
          },
          {
            type: "IMAGE",
            url: "/assets/templates/entity_kalakritikarishma/collage2.webp",
          },
          {
            type: "IMAGE",
            url: "/assets/templates/entity_kalakritikarishma/collage3.webp",
          },
          {
            type: "IMAGE",
            url: "/assets/templates/entity_kalakritikarishma/collage4.webp",
          }
        ]
      }
    },
    {
      type: "WORK_SAMPLES",
      data: {
        items: [
          {
            type: "IMAGE",
            url: "/assets/templates/entity_kalakritikarishma/work1.webp",
            creator: "Jui Mhatre",  
          },
          {
            type: "IMAGE",
            url: "/assets/templates/entity_kalakritikarishma/work2.webp",
            creator: "Krupa Shah",
          },
          {
            type: "IMAGE",
            url: "/assets/templates/entity_kalakritikarishma/work3.webp",
            creator: "Deekshitha Ameen",
          }
        ]
      }
    }
  ],
};

export default entity_kalakritikarishma;
